export default {
  svg: [
    "webrezpro",
    "sirvoy",
    "smoobu",
    "tripadvisor",
    "myrent",
    "reservotron",
    "prestigecharlevoix",
    "houfy",
    "igms",
    "hotelrunner",
    "hostfully",
    "hipcamp",
    "guestyforhosts",
    "glampinghub",
    "expediagroup",
    "checkfront",
    "bookingsync",
    "abritel"
  ],
  png: [
    "vrbo",
    "lodgify",
    "reservit",
    "guesty",
    "booking",
    "hostaway",
    "airbnb",
    "yourporter",
    "elloha",
    "reservpro",
    "tokeet",
    "rsvpchalets",
    "leschaletsrelaxstations",
    "leschaletsdestinee",
    "leschaletsdelavallee",
    "homeaway",
    "google",
    "chaletsparadiski",
    "chaletsauquebec",
    "bookingautomation"
  ],
  jpg: ["domainegagnon", "myallocator"],
  webp: ["monsieurchalets", "experiencekamouraska"]
};
