import { FC } from "react";
import { getLocale } from "modules/settings/selectors";
import { useDispatch, useSelector } from "react-redux";
import changeSearchKeyword from "modules/listing-calendar/store/actions/change-search-keyword";
import classes from "./search-text-input-mobile.module.scss";
import messages from "./messages";
import selectKeyword from "modules/listing-calendar/store/selectors/select-keyword";
import TextField from "@design-library/TextField";
import useLang from "@design-library/utils/useLang";

interface Props {}

export const SearchTextInputMobile: FC<Props> = () => {
  const keyword = useSelector(selectKeyword);

  const dispatch = useDispatch();

  const onChange = (value: string) => {
    dispatch(changeSearchKeyword(value));
  };

  const locale = useSelector(getLocale);

  const lang = useLang(messages, locale);

  return (
    <div className={classes.container}>
      <TextField
        // @ts-expect-error the prop "type" is not typed correctly
        type="search"
        placeholder={lang.search_placeholder}
        value={keyword}
        onChange={onChange}
        // icon={SearchInputIcon}
        theme="filled"
      />
    </div>
  );
};

export default SearchTextInputMobile;

// const SearchInputIcon = () => <i className="fa fa-search" />;
